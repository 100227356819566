import * as React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import "./PaymentPage.css";

const stripePromise = loadStripe(
  process.env.REACT_APP_PUBLISHABLE_API_KEY_STRIPE
);

const PaymentPage = ({ AgreementResponse }) => {
  const fetchClientSecret = React.useCallback(() => {
    return new Promise((resolve, reject) => {
      if (
        AgreementResponse.response &&
        AgreementResponse.response !== "Chassis"
      ) {
        resolve(AgreementResponse.response.firstPaymentIds);
      } else if (AgreementResponse.response !== "Chassis") {
        resolve(AgreementResponse.firstPaymentIds);
      } else if (AgreementResponse.response === "Chassis") {
      }
    });
  }, [AgreementResponse]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout" className="PaymentPage-stripe-embedded-container">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
        className="PaymentPage-embedded-object"
      >
        <EmbeddedCheckout style={{ backgroundColor: "black" }} />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default PaymentPage;
