import { axiosInstance } from "./appInsights";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
  "Content-Type": "application/json",
};
export const GetLinkByAgreementId = async (AgreementId) => {
  const config = {
    headers,
    url: process.env.REACT_APP_GET_LINK_BY_AGREEMENT_ID,
    params: { AgreementId },
    method: "GET",
    nameEvent: "CartView-GetLinkByAgreementId",
  };

  try {
    const response = await axiosInstance(config);
    return response.data;
  } catch (err) {
    console.error(err);
  }
};

export const CreateAgreementToSpots = async (payload) => {
  const config = {
    headers,
    url: process.env.REACT_APP_CREATE_AGREEMENT,
    method: "POST",
    data: payload,
    nameEvent: "CartView-CreateAgreementToSpots",
  };
  try {
    const response = await axiosInstance(config);
    if (response.data) {
      return response.data;
    }
  } catch (err) {
    return err.response;
  }
};

export const CreateAgreementToChassisRental = async (payload) => {
  const config = {
    headers,
    url: process.env.REACT_APP_CREATE_AGREEMENT_CHASSIS_RENTAL,
    method: "POST",
    data: payload,
    nameEvent: "CartView-CreateAgreementToChassisRental",
  };
  try {
    const response = await axiosInstance(config);
    if (response.data) {
      return response.data;
    }
  } catch (err) {
    return err.response;
  }
};
