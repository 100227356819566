// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .LoginReact-circular-progress {
  color: #fc4817;
  scale: 2;
}

body .LoginReact-circular-progress-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

body .CartView-DetailSummaryCard-UploadButton {
  background-color: #fc4817;
  color: white;
  border-radius: 5px;
  word-break: break-word;
  max-width: 300px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  outline: none;
  transition: 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/components/CartView/DetailSummary/DetailSummary.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB","sourcesContent":["body .LoginReact-circular-progress {\n  color: #fc4817;\n  scale: 2;\n}\n\nbody .LoginReact-circular-progress-box {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\nbody .CartView-DetailSummaryCard-UploadButton {\n  background-color: #fc4817;\n  color: white;\n  border-radius: 5px;\n  word-break: break-word;\n  max-width: 300px;\n  overflow: hidden;\n  cursor: pointer;\n  border: none;\n  outline: none;\n  transition: 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
