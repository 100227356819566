import React from "react";
import { Paper, Typography, CircularProgress, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InfoIcon from "@mui/icons-material/Info";
import CheckIcon from "@mui/icons-material/Check";
import { GetLinkByAgreementId } from "../../../services/PaymentStripeServices";
import {
  editItemInLocalStorage,
  getItemFromLocalStorage,
} from "../../../services/localStorageService";
import "./PaymentProcessed.css";
import QRCode from "react-qr-code";
import Cookies from "universal-cookie";
import jsPDF from "jspdf";

const PaymentProcessed = () => {
  const cookies = new Cookies();
  const [paymentStatus, setPaymentStatus] = React.useState("open");
  const [typeOfRent, setTypeOfRent] = React.useState("spot");
  const [qrInfo, setQrInfo] = React.useState(
    JSON.stringify({
      type: "",
      scac_code: "",
      agreementId: "",
    })
  );

  const [yardAndSpots, setYardAndSpots] = React.useState("");

  function handleDownloadQrAsPNG() {
    const svgElement = document.getElementById("qrChassisRentalCode");
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imgURL = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      let aEl = document.createElement("a");
      aEl.href = imgURL;
      aEl.download =
        "ChassisRental-" + cookies.get("sessionCosmos").scac_code + ".png";
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    };
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  }

  function handleDownloadQrAsPDF() {
    const svgElement = document.getElementById("qrChassisRentalCode");
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imgURL = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const base64Image = imgURL.split(",")[1];
      pdf.addImage(base64Image, "PNG", 15, 40, 180, 160);
      pdf.save(
        "ChassisRental-" + cookies.get("sessionCosmos").scac_code + ".pdf"
      );
    };
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  }

  function handleGetAgreementIdFromUrl() {
    const url = window.location.href;
    const urlSplit = url.split("AgreementId=");
    return urlSplit[1];
  }

  function handleEditPaymentObjectFromLocalStorage(status) {
    const paymentObject = getItemFromLocalStorage("paymentObject");
    if (!paymentObject) return;
    paymentObject[0].paymentStatus = status;
    editItemInLocalStorage("paymentObject", paymentObject);
    const event = new CustomEvent("responseStripeCompleted", {
      detail: { status: status },
    });
    window.dispatchEvent(event);
  }

  function handleValidateIsChassisRental() {
    const paymentObject = getItemFromLocalStorage("paymentObject");
    if (paymentObject && paymentObject[0].itemProduct.itemType === "Chassis") {
      setTypeOfRent("chassisRental");
      setQrInfo(
        JSON.stringify({
          type: "chassisRental",
          scac_code: cookies.get("sessionCosmos").scac_code,
          agreementId: handleGetAgreementIdFromUrl(),
        })
      );
    }
  }

  function handleSetStartDateToTakeSpots() {
    const paymentObject = getItemFromLocalStorage("paymentObject");
    setYardAndSpots({
      yard: paymentObject[0].itemProduct.ProductsChosen.YardSelected.CodeName,
      dateFrom: paymentObject[0].itemProduct.StartDate,
    });
  }

  function handleValidateIfIsChassisRental() {
    const paymentObject = getItemFromLocalStorage("paymentObject");
    if (paymentObject && paymentObject[0].itemProduct.itemType === "Chassis") {
      return true;
    }
    return false;
  }

  React.useEffect(() => {
    const isChassis = handleValidateIfIsChassisRental();
    if (!isChassis) {
      function GetPaymentStatus() {
        const AgreementId = handleGetAgreementIdFromUrl();
        GetLinkByAgreementId(AgreementId)
          .then((data) => {
            if (data.PaymentResponse.statusPayment !== "completed") {
              return;
            }
            handleValidateIsChassisRental();
            setPaymentStatus(data.PaymentResponse.statusPayment);
            handleEditPaymentObjectFromLocalStorage("completed");
            clearInterval(getStatus);
            handleSetStartDateToTakeSpots();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      let maxAttempts = 7;
      const getStatus = setInterval(() => {
        if (maxAttempts !== 0) {
          GetPaymentStatus();
        } else {
          clearInterval(getStatus);
          handleValidateIsChassisRental();
          setPaymentStatus("created");
          handleEditPaymentObjectFromLocalStorage("created");
          handleSetStartDateToTakeSpots();
        }
        maxAttempts--;
      }, 5000);
    } else {
      // handleValidateIsChassisRental();
      setPaymentStatus("created");
      setTypeOfRent("chassisRental");
      handleEditPaymentObjectFromLocalStorage("created");
      handleSetStartDateToTakeSpots();
    }
  }, []);

  return (
    <>
      {paymentStatus === "open" && (
        <Paper elevation={2} className="CarView-payment-processed-paper">
          <Grid container className="CarView-payment-processed-grid-container">
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="CartView-payment-processed-grid-icon"
            >
              <CircularProgress
                thickness={7}
                className="CartView-circular-progress"
              />
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="CartView-payment-processed-text-status">
                Payment in process
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {paymentStatus === "completed" && typeOfRent !== "chassisRental" && (
        <Paper elevation={2} className="CarView-payment-processed-paper">
          <Grid container className="CarView-payment-processed-grid-container">
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="CartView-payment-processed-grid-icon"
            >
              <CheckIcon className="CartView-payment-processed-icon" />
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="CartView-payment-processed-text-status">
                ¡Your booking is successful!
              </Typography>
              <Typography className="CartView-payment-processed-text-status-description">
                Your booking has been processed successfully. In a period of 24
                hours, you will receive an email with the confirmation of your
                order.
              </Typography>{" "}
              <Typography className="CartView-payment-processed-text-status-description">
                You are available to take spots from the yard since:{" "}
                {yardAndSpots.dateFrom}
              </Typography>{" "}
              <Typography className="CartView-payment-processed-text-status-description">
                In Yard: {yardAndSpots.yard}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {paymentStatus === "created" && typeOfRent !== "chassisRental" && (
        <Paper elevation={2} className="CarView-payment-processed-paper">
          <Grid container className="CarView-payment-processed-grid-container">
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="CartView-payment-processed-grid-icon"
            >
              <InfoIcon className="CartView-payment-processed-icon" />
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="CartView-payment-processed-text-status">
                ¡Your booking is processed!
              </Typography>
              <Typography className="CartView-payment-processed-text-status-description">
                Your booking has been processed, contact us to check the status
                of your order.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
      {paymentStatus && typeOfRent === "chassisRental" && (
        <Paper elevation={2} className="CarView-payment-processed-paper">
          <Grid container className="CarView-payment-processed-grid-container">
            {/* <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="CartView-payment-processed-grid-icon"
            >
              <QRCode
                id="qrChassisRentalCode"
                style={{ height: "60%", maxWidth: "60%", width: "60%" }}
                value={qrInfo}
                viewBox={`0 0 256 256`}
              />
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} container>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="CartView-payment-processed-button-grid"
              >
                <Button
                  className="CartView-payment-processed-button"
                  variant="contained"
                  onClick={handleDownloadQrAsPNG}
                >
                  Download in PNG
                </Button>
              </Grid>
              <Grid
                xl={6}
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="CartView-payment-processed-button-grid"
              >
                <Button
                  className="CartView-payment-processed-button"
                  variant="contained"
                  onClick={handleDownloadQrAsPDF}
                >
                  Download in PDF
                </Button>
              </Grid>
            </Grid> */}{" "}
            <Grid
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="CartView-payment-processed-grid-icon"
            >
              <CheckIcon className="CartView-payment-processed-icon" />
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography className="CartView-payment-processed-text-status">
                Chassis Rental has been ordered
              </Typography>
              <Typography className="CartView-payment-processed-text-status-description">
                Your order has been processed, it will be pass to an approval by
                our team. You will receive an email with the link to pay the
                order.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default PaymentProcessed;
