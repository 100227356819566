import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { formatAsUSD } from "../../../utils/moneyUtils";

const OrderSummaryInnerTable = ({ priceChosen }) => {
  const getRegularPrice = (index) => {
    return priceChosen.Regular &&
      priceChosen.Regular[index] &&
      priceChosen.Regular[index].Cost
      ? formatAsUSD(priceChosen.Regular[index].Cost)
      : formatAsUSD(null);
  };

  const getRegularDescription = (index) => {
    return priceChosen.Regular &&
      priceChosen.Regular[index] &&
      priceChosen.Regular[index].Description
      ? priceChosen.Regular[index].Description
      : "";
  };

  const getOveragePrice = (index) => {
    return priceChosen.Overage &&
      priceChosen.Overage[index] &&
      priceChosen.Overage[index].Cost
      ? formatAsUSD(priceChosen.Overage[index].Cost)
      : formatAsUSD(null);
  };

  return (
    <Table
      size="small"
      width="100%"
      aria-label="a dense table"
      sx={{
        [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
        },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell sx={{ px: 1 }}>
            <strong>Product</strong>
          </TableCell>
          <TableCell sx={{ px: 1 }}>
            <strong>Price/Day</strong>
          </TableCell>
          <TableCell sx={{ px: 1 }}>
            <strong>Overage</strong>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {priceChosen.Regular.map((price, index) => (
          <TableRow key={index}>
            {getRegularDescription(index) === "" ? null : (
              <>
                <TableCell sx={{ px: 1 }}>
                  {getRegularDescription(index)}
                </TableCell>
                <TableCell sx={{ px: 1 }}>{getRegularPrice(index)}</TableCell>
                <TableCell sx={{ px: 1 }}>{getOveragePrice(index)}</TableCell>
              </>
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrderSummaryInnerTable;
