/* eslint-disable no-undef */
import Cookies from "universal-cookie";
import { getCurrentDate } from "../utils/datesUtils";
import { updateItemInCart } from "../services/localStorageService";
import * as React from "react";
const integrationKey = process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY;
const getUrlDocusignByClient = process.env.REACT_APP_GET_URL_DOCUSIGN_BY_CLIENT;
const apimOcpKey = process.env.REACT_APP_APIM_OCP_KEY;

const getSessionCosmos = () => {
  const cookies = new Cookies();
  return cookies.get("sessionCosmos");
};

const getPriceUnit = (activeProduct) => {
  return activeProduct.ProductsChosen.PriceChosen.Regular.reduce(
    (prev, current) => (prev.Cost < current.Cost ? prev : current)
  ).Cost;
};

export const fetchDocuSignDetails = async (activeProduct) => {
  try {
    const sessionCosmos = getSessionCosmos();
    const currentDate = getCurrentDate();

    if (!sessionCosmos || !sessionCosmos.scac_code) {
      throw new Error("Please login to view the Rental Agreement Policy");
    }

    if (!activeProduct) {
      throw new Error("Active product information is missing");
    }

    const priceUnit = getPriceUnit(activeProduct);

    const response = await fetch(getUrlDocusignByClient, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": apimOcpKey,
      },
      body: JSON.stringify({
        DateSigned: currentDate,
        ScacCodeCarrier: sessionCosmos.scac_code,
        AdressYard: activeProduct.ProductsChosen.YardSelected.CodeName,
        PriceUnit: priceUnit,
        TypeSuscription: activeProduct.ProductsChosen.PriceChosen.Frequency,
        // TypeService: activeProduct.itemType === "Spots" ? "Spots" : "Chassis",
      }),
    });

    const data = await response.json();
    if (response.ok) {
      return {
        url: data.url,
        idEnvelope: data.idEnvelop,
      };
    } else {
      throw new Error(data.message || "Failed to fetch DocuSign details");
    }
  } catch (error) {
    console.error("Error fetching DocuSign details:", error);
    throw error;
  }
};

export const loadDocuSignScript = () => {
  return new Promise((resolve, reject) => {
    if (window.DocuSign) {
      resolve(window.DocuSign);
    } else {
      reject("Failed to load DocuSign library");
    }
  });
};
export const initializeDocuSign = (
  url,
  setLoading,
  setError,
  idEnvelope,
  activeProduct,
  onActiveProductChange
) => {
  window.DocuSign.loadDocuSign(integrationKey)
    .then((docusign) => {
      const signing = docusign.signing({
        url: url,
        displayFormat: "focused",
        style: {
          branding: {
            primaryButton: {
              backgroundColor: "#1578FB",
              color: "#fff",
            },
          },
          signingNavigationButton: {
            finishText: "Click here to finish",
            position: "bottom-center",
          },
        },
      });

      signing.on("ready", () => {
        setLoading(false);
      });

      signing.on("sessionEnd", (event) => {
        activeProduct.product.idEnvelope = idEnvelope;

        onActiveProductChange(activeProduct.product, activeProduct.index);

        updateItemInCart("yardProducts", activeProduct.index, {
          ...activeProduct.product,
        });
        window.dispatchEvent(new Event("localStorageHasChanged"));
      });

      signing.mount("#agreement");
    })
    .catch((ex) => {
      console.error("Error loading DocuSign:", ex);
      setError(ex.message);
      setLoading(false);
    });
};
