import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const OrderSummaryCardTitle = ({
  typeOfProduct,
  text,
  type,
  spots,
  inPayment,
  paymentState,
  status,
}) => {
  // Función para determinar el texto basado en las condiciones
  const getStatusText = (inPayment, paymentState) => {
    if (inPayment && paymentState)
      return status === "completed" ? " - Paid" : " - Processed";
    if (inPayment && !paymentState) return " - In Payment";
    return "";
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Typography
        color="primary.dark"
        sx={{
          fontSize: 26,
          fontWeight: "bold",
        }}
      >
        {text} {typeOfProduct === "Spots" ? " Yard / " : " Chassis / "}
      </Typography>
      <Typography
        color="secondary.main"
        sx={{
          fontSize: 26,
          fontWeight: "bold",
        }}
      >
        {" " + type} - {spots === 0 ? 1 : spots}{" "}
        {typeOfProduct === "Spots" ? "Spots" : "Chassis"}
        {getStatusText(inPayment, paymentState)}
      </Typography>
    </Box>
  );
};

export default OrderSummaryCardTitle;
