import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Button,
  Typography,
  Slide,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "./RentalAgreementDialog.css";

import {
  fetchDocuSignDetails,
  loadDocuSignScript,
  initializeDocuSign,
} from "../../../services/docusignConfig";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RentalAgreementDialog = ({ activeProduct, onActiveProductChange }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const agreementRef = useRef(null);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
    setError(null);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
    if (agreementRef.current) {
      agreementRef.current.innerHTML = "";
    }
  }, []);

  useEffect(() => {
    if (dialogOpen) {
      const fetchAndInitialize = async () => {
        setLoading(true);

        if (activeProduct.product.idEnvelope) {
          setLoading(false);
          setDialogOpen(false);
          return;
        }

        try {
          const { url, idEnvelope } = await fetchDocuSignDetails(
            activeProduct.product
          );
          await loadDocuSignScript();
          initializeDocuSign(
            url,
            setLoading,
            setError,
            idEnvelope,
            activeProduct,
            onActiveProductChange
          );
        } catch (error) {
          console.error("Error during fetch and initialize:", error);
          setError(error.message);
          setLoading(false);
        }
      };
      window.addEventListener("docuSignLoading", () => {
        setDialogOpen(true);
      });
      fetchAndInitialize();
    }
  }, [dialogOpen, activeProduct]);

  return (
    <>
      <Typography
        variant="body2"
        color="primary"
        onClick={handleOpenDialog}
        style={{ cursor: "pointer", fontSize: 16 }}
      >
        Rental Agreement Policy
      </Typography>
      <Dialog
        open={dialogOpen}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="md"
        scroll="paper"
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            borderRadius: 16,
          },
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          style: {
            backdropFilter: "blur(26px)",
            backgroundColor: "rgba(34, 34, 79, 0.30)",
          },
        }}
      >
        {/* {" "}
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogTitle style={{ fontSize: 40, fontWeight: "bold" }}>
          Rental Agreement Policy
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <div
            id="agreement"
            ref={agreementRef}
            className="docusign-agreement"
          />
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography
                color="primary.dark"
                sx={{
                  fontSize: 26,
                  fontWeight: "bold",
                }}
              >
                This may take a few seconds...
              </Typography>
              <CircularProgress
                size={50}
                thickness={4}
                sx={{ ml: 2 }}
                color="secondary"
              />
            </Box>
          )}
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 4 }}>
          {/* <Button
            onClick={handleCloseDialog}
            size="large"
            variant="contained"
            color="secondary"
            sx={{ width: 150, borderRadius: 2 }}
          >
            <Typography textTransform="capitalize" variant="button">
              Accept
            </Typography>
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RentalAgreementDialog;
