import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import FullCard from "../components/EmptyCart/EmptyCard/FullCard";
import PaymentProcessed from "../components/StripeEmbedd/PaymentProcessed/PaymentProcessed";

const EmptyCartContainer = () => {
  const [sesionId, setSessionId] = React.useState(false);
  function handleCkeckSessionPayment() {
    const url = window.location.href;
    if (url.includes("AgreementId")) {
      setSessionId(true);
    }
  }

  React.useEffect(() => {
    handleCkeckSessionPayment();

  }, []);
  return (
    <Box sx={{}}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8 }}>
        <Grid
          xs={4}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <ShoppingCartIcon color="primary.dark" sx={{ fontSize: 35, mr: 1 }} />
          <Typography
            color="primary.dark"
            style={{
              fontSize: 60,
              fontWeight: "bold",
            }}
          >
            Order summary
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, md: 8 }}>
        {sesionId ? (
          <>
            <Grid xs={2} md={4} sx={{ flexDirection: "column" }}>
              <FullCard />
            </Grid>
            <Grid xs={2} md={4} sx={{ flexDirection: "column" }}>
              <PaymentProcessed />
            </Grid>
          </>
        ) : (
          <Grid xs={4} md={8} sx={{ flexDirection: "column" }}>
            <FullCard />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EmptyCartContainer;
