import * as React from "react";
import { ThemeProvider } from "@mui/material/styles";

import {
  getItemFromLocalStorage,
  removeItemFromCart,
} from "./services/localStorageService";

import Container from "@mui/material/Container";
import CartViewContainer from "./containers/CartViewContainer";
import EmptyCartContainer from "./containers/EmptyCartContainer";
import NotificationAlert from "./components/SnackBar/SnackBar";
import Cookies from "universal-cookie";
import { SnackBarProvider } from "./context/SnackBarContext";
import theme from "./styles/theme";
import "./styles/global.css";

export default function Root(props) {
  const cookies = new Cookies();

  const [userCart, setUserCart] = React.useState({});

  const handleRemoveItemFromCart = (index) => {
    const newCart = [...userCart];
    newCart.splice(index, 1);
    setUserCart(newCart);
    removeItemFromCart("yardProducts", index);
  };

  React.useEffect(() => {
    function handleCleanYardProductOfLocalStorage() {
      const sessionCosmos = cookies.cookies.sessionCosmos;
      if (!sessionCosmos && sessionCosmos === undefined) {
        localStorage.removeItem("yardProducts");
      }
    }

    function handleCreateSessionCosmosRemovedListener() {
      const sessionCosmos = cookies.cookies.sessionCosmos;
      if (sessionCosmos && sessionCosmos !== undefined) {
        cookies.addChangeListener(handleCleanYardProductOfLocalStorage);
      }
    }

    function handleSetUserCart() {
      const cart = getItemFromLocalStorage("yardProducts");
      setUserCart(cart);
    }

    window.addEventListener("localStorageHasChanged", handleSetUserCart);
    cookies.addChangeListener(handleCreateSessionCosmosRemovedListener);

    handleSetUserCart();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackBarProvider>
        <Container maxWidth={false}>
          {userCart && userCart.length > 0 ? (
            <CartViewContainer
              userCart={userCart}
              onRemoveItemFromCart={handleRemoveItemFromCart}
            />
          ) : (
            <EmptyCartContainer />
          )}
        </Container>
        <NotificationAlert />
      </SnackBarProvider>
    </ThemeProvider>
  );
}
